import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import { proccessTypes } from '../utils/common'
import {
    toggleCurrentStateAnswerArr,
    togglePercentageHeader,
    toggleFutureStateAnswerArr,
    toggleToolsToGetAnswerArr,
    toggleToolsSelectQuestion
} from '../redux/process/actions'
import logoImage from '../assets/images/logo.png';
// import organizationImage from '../assets/images/organization.png';
// import talentImage from '../assets/images/talent.png';
// import goalImage from '../assets/images/goal.png';
import 'react-circular-progressbar/dist/styles.css';
import { Typography } from '@mui/material';

export default function Header(props) {

    const dispatch = useDispatch();

    const location = useLocation();
    const pathname = location.pathname;

    const { type, introData, percentage, currentStateAnswers, futureStateAnswers, toolsToGetStateAnswers, toolsSelectQuestions } = useSelector((s) => s.process);
    // const [questionImg, setQuestionImg] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [currentPerc, setCurrentPerc] = useState(0);
    const [futurePerc, setFuturePerc] = useState(0);
    const [toolsPerc, setToolsPerc] = useState(0);
    const [percentIsInfinity, setPercentIsInfinity] = useState(false);
    const [showPercentage, setShowPercentage] = useState(true);
    // const [percentage, setPercentage] = useState(0);

    // const renderImage = () => {
    //     var imgBlock = '';
    //     if (currentImgIndex !== undefined) {
    //         if (type === proccessTypes.currentQuestions || type.includes(`${proccessTypes.currentQuestions_question}-`)) {
    //             switch (currentImgIndex) {
    //                 case 0:
    //                     imgBlock = organizationImage;
    //                     break;
    //                 case 1:
    //                     imgBlock = talentImage;
    //                     break;
    //                 case 2:
    //                     imgBlock = goalImage;
    //                     break;
    //                 default:
    //                     imgBlock = null;
    //                     break;
    //             }
    //         } else {
    //             imgBlock = null
    //         }
    //     }

    //     if (imgBlock) {
    //         setQuestionImg(imgBlock);
    //         return;
    //     }
    //     setQuestionImg('');
    //     return;
    // };

    useEffect(() => {
        const email = localStorage.getItem('email');
        setCompanyName(email);
        // renderPer();
    }, [introData]);

    const JsonParse = (str) => {
        return JSON.parse(str);
    }

    useEffect(() => {
        const currentAnswe = localStorage.getItem('currentStateAnswers');
        const futureAnswe = localStorage.getItem('futureStateAnswers');
        const toolsAnswe = localStorage.getItem('toolsToGetStateAnswers');
        const toolsquesTy = localStorage.getItem('toolsQuestionTypesIds');

        if (currentAnswe && currentStateAnswers && currentStateAnswers.length === 0) {
            const resCurr = JsonParse(currentAnswe);
            dispatch(toggleCurrentStateAnswerArr(resCurr))
        }

        if (futureAnswe && futureStateAnswers && futureStateAnswers.length === 0) {
            const resFutrure = JsonParse(futureAnswe);
            dispatch(toggleFutureStateAnswerArr(resFutrure))
        }

        if (toolsAnswe && toolsToGetStateAnswers && toolsToGetStateAnswers.length === 0) {
            const resTools = JsonParse(toolsAnswe);
            dispatch(toggleToolsToGetAnswerArr(resTools))
        }

        if (toolsquesTy) {
            const resToolsQ = JsonParse(toolsquesTy);
            dispatch(toggleToolsSelectQuestion(resToolsQ))
        }
    }, [])

    const percentAdded = () => {
        const flow = localStorage.getItem('flow');
        if (flow) {
            const totalSkip = currentPerc + futurePerc + 50;

            if (totalSkip !== Infinity) {
                setPercentIsInfinity(false)
                if (totalSkip > 0) {
                    if (totalSkip > 100) {
                        dispatch(togglePercentageHeader(100))
                    } else {
                        dispatch(togglePercentageHeader(Math.round(totalSkip)))
                    }
                }
            } else {
                setPercentIsInfinity(true)
                currentGetAnswer();
                futureGetAnswer();
                toolsGetAnswer();
            }
            return;
        }
        setPercentIsInfinity(false)
        // renderPer();
        if (currentPerc > 0 || futurePerc > 0 || toolsPerc > 0) {
            const total = currentPerc + futurePerc + toolsPerc;
            // console.log(total)
            if (total !== Infinity) {
                setPercentIsInfinity(false)
                if (total > 0) {
                    if (total > 100) {
                        dispatch(togglePercentageHeader(100))
                    } else {
                        dispatch(togglePercentageHeader(Math.round(total)))
                    }
                } else {
                    dispatch(togglePercentageHeader(0))
                }
            } else {
                setPercentIsInfinity(true)
                setTimeout(() => {
                    currentGetAnswer();
                    futureGetAnswer();
                    toolsGetAnswer();
                }, 1000)
            }
        } else {
            // percentAdded();
        }
    }


    const getQuesByType = (typ) => {
        // const inData = localStorage.getItem('introData');
        const toolsId = localStorage.getItem('toolsQuestionTypesIds');
        // const dataInto = JSON.parse(inData)
        const dataInto = introData;

        if (Array.isArray(dataInto) && dataInto.length && dataInto.length > 0) {
            const fin = dataInto.find((item) => item.stateType === typ);
            if (Array.isArray(fin.questions) && fin.questions.length && fin.questions.length > 0) {
                if (typ === proccessTypes.ttgt) {
                    const ids = toolsId ? JSON.parse(toolsId) : [];
                    return fin.questions?.filter((item) => ids.includes(item.questionTypeId))
                }
                return fin.questions;
            }
            return []
        }

        return [];
    }

    async function currentAnswerPerc(arr) {
        let currTotal = 0;
        let perQusPer = 0;
        const currenQPer = 25;
        const questions = await getQuesByType(proccessTypes.currentState);
        perQusPer = currenQPer / questions.length
        // console.log(arr);
        if (Array.isArray(arr) && arr.length && arr.length > 0 && perQusPer > 0) {
            currTotal = arr.length * perQusPer;
        }
        setCurrentPerc(currTotal)
    }

    const futureAnswerPerc = async (arr) => {
        let futurTotal = 0;
        let perQus = 0;
        const futurQPer = 25;
        const questions = await getQuesByType(proccessTypes.futureState);
        perQus = futurQPer / questions.length

        if (Array.isArray(arr) && arr.length && arr.length > 0 && perQus > 0) {
            futurTotal = arr.length * perQus;
        }

        setFuturePerc(futurTotal)
    }

    const toolsAnswerPerc = async (arr) => {
        // let toolTotal = 0;
        let perQ = 0;
        const qPer = 50;
        const questions = await getQuesByType(proccessTypes.ttgt);


        perQ = qPer / questions.length;
        if (Array.isArray(arr) && arr.length && arr.length > 0 && perQ > 0) {
            const toolTotal = arr.length * perQ;
            setToolsPerc(toolTotal)
        }

    }

    const currentGetAnswer = () => {
        const currentAnswerLocal = localStorage.getItem('currentStateAnswers')
        if (currentAnswerLocal) {
            const currAnswers = JSON.parse(currentAnswerLocal);
            setTimeout(() => currentAnswerPerc(currAnswers), 1500);
        }
    }

    const futureGetAnswer = () => {
        const futureAnswerLocal = localStorage.getItem('futureStateAnswers');
        if (futureAnswerLocal) {
            const futurAnswers = JSON.parse(futureAnswerLocal);
            setTimeout(() => futureAnswerPerc(futurAnswers), 1500);
        }
    }

    const toolsGetAnswer = () => {
        const toolsAnswerLocal = localStorage.getItem('toolsToGetStateAnswers')
        if (toolsAnswerLocal) {
            const toolsAnswer = JSON.parse(toolsAnswerLocal);
            setTimeout(() => toolsAnswerPerc(toolsAnswer), 1500);
        }
    }

    useEffect(() => {
        currentGetAnswer();
    }, [currentStateAnswers])

    useEffect(() => {
        futureGetAnswer();
    }, [futureStateAnswers])

    useEffect(() => {
        toolsGetAnswer();
    }, [toolsToGetStateAnswers]);

    useEffect(() => {
        // console.log(percentIsInfinity);
        // if (percentIsInfinity) {
        // if (currentPerc === Infinity || futurePerc === Infinity || toolsPerc === Infinity) {
        //     console.log('if infinity')
        //     currentGetAnswer();
        //     futureGetAnswer();
        //     toolsGetAnswer();
        //     setPercentIsInfinity(true)
        //     return
        // }
        percentAdded();
        // } else {
        // const timeOut = setTimeout(() => { percentAdded() }, 2000);
        // return () => {
        //     // clearTimeout(timeOut)
        // }
        // }
    }, [percentIsInfinity, currentPerc, futurePerc, toolsPerc, type, toolsSelectQuestions])


    useEffect(() => {
        if (type === proccessTypes.dashboard_page || pathname === '/dashboard') {
            setShowPercentage(false);
        } else {
            setShowPercentage(true)
        }
    }, [type])


    // useEffect(() => {
    //     renderImage();

    //     return () => {
    //         renderImage();
    //     }
    // }, [currentImgIndex])

    return (
        <div className='d-flex justify-content-between align-items-center pt-20'>
            <div className='d-flex align-items-center justify-content-between w-100 pr-40 pl-30'>
                <div className='d-flex justify-content-between align-items-center'>
                    <img src={logoImage} alt='logo' className='wd-75px mr-30' />
                    {companyName ? <Typography className='text-sky font-600 mr-15 word-break' component={'span'} variant='span'>{companyName}</Typography> : null}
                </div>
                {/* {questionImg ? <img src={questionImg} className='wd-50px' /> : null} */}
                {showPercentage ?
                    <CircularProgressbar
                        strokeWidth={12}
                        value={percentage}
                        text={`${percentage}%`}
                        className='progressbar-width'
                    />
                    :
                    null
                }
            </div>
        </div>
    )
}
