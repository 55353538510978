import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store'
import { ToastContainer } from "react-toastify";
import Routes from './Routes';
import './App.css';

const history = createBrowserHistory();

export default function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
          <ToastContainer autoClose={2000} pauseOnHover />
        </Router>
      </Provider>
    </div>
  );
}
