import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { getParamsFromURL } from '../utils/common';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('token');
    return <Route
        {...rest}
        render={(props) => {
            const params = getParamsFromURL(props.location.search);
            if (props.location.pathname === '/dashboard' && params.get('userGuid')) {
                return <Component {...props} />
            }
            return token ?
                <Component {...props} />
                :
                <Redirect to="/" />
        }}
    />
}

export default PrivateRoute;
