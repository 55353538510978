import { decodeToken } from "react-jwt";
import CurrentstateSummaryImage from '../assets/images/currentstate-summary.png';
import FuturestateSummaryImage from '../assets/images/futurestate-summary.png';
import ttgtSummaryImage from '../assets/images/organizational-summary.png';

export const proccessTypes = {
    intro: '',
    currentState: "CS",
    currentQuestions: "currentQuestions",
    currentQuestions_question: 'currentQuestions_question',
    current_summary: 'current_summary',
    current_strategy: 'current_strategy',
    futureState: "FS",
    futureQuestions: "futureQuestions",
    futureQuestions_question: 'futureQuestions_question',
    future_summary: 'future_summary',
    future_strategy: 'future_strategy',
    ttgt: "TTGT",
    ttgtQuestions: "ttgtQuestions",
    ttgtQuestions_question: "ttgtQuestions_question",
    ttgt_summary: "ttgt_summary",
    next_steps: "next_steps",
    thanks_page: "thanks_page",
    dashboard_page: "dashboard_page",
}

export const typeOfStrategyVideo = {
    AD_HOC: "ADH",
    ORG_COACHING: "ORG",
    PROG_COACHING: "PROG",
    COACHING_CULTURE: "CC",
    DRIVER_OF_BUSINESS: "CDBS",
    MIXRESULT: "MIXRESULT"
}

export const stepsArr = [
    'Current state',
    'Future state',
    'Tools to get there',
    'Next steps',
];

export const currentSummary = {
    img: CurrentstateSummaryImage,
    img_alt: "Current State Summary",
    heading: 'SUMMARY',
    tag: 'Current state',
    title: 'Organizational Levels of Coaching',
    description: [
        "Thank you for answering those questions about the current state of coaching in your organization.",
        "The next screen will show you the level that most accurately describes how coaching is currently used in your organization. Two important notes: First, these levels are not discrete categories, but points along a continuum. Most organizations fall somewhere between two or more levels. Second, the levels further along the continuum aren’t necessarily better than the levels before them. Rather, they represent how fully your organization is realizing the benefits coaching can offer."
    ]
}

export const futureSummary = {
    img: FuturestateSummaryImage,
    img_alt: "Future state Summary",
    heading: 'SUMMARY',
    tag: 'Future state',
    title: 'Organizational Levels of Coaching',
    description: [
        "Thank you for answering those questions about the future state of coaching in your organization.",
        "The next screen will show you the level that most accurately describes how you want coaching to be used in your organization. Two important notes: First, these levels are not discrete categories, but points along a continuum. Most organizations fall somewhere between two or more levels. Second, the levels further along the continuum aren’t necessarily better than the levels before them. Rather, they represent how fully your organization is realizing the benefits coaching can offer."
    ]
}

// "These are the organizational levels of coaching our clients find themselves in.",
export const ttgtSummary = {
    img: ttgtSummaryImage,
    img_alt: "Tools to get there",
    heading: 'SUMMARY',
    tag: 'Tools to get there',
    title: 'Organizational Levels of Coaching',
    description: [
        "By answering the questions in section 3; Tools to get there, you've helped us map you to the tools most likely to structure an effective program for where your team/company/individual is at RIGHT NOW."
    ]
}

export const processSubmitFormData = (data) => {
    if (!data) {
        return { status: false, data: [] }
    }

    if (Array.isArray(data) && data.length) {
        let formData = []
        for (var i = 0; i < data.length; i++) {
            if (data[i].stateType !== proccessTypes.ttgt) {
                const questions = data[i].questions;
                for (var s = 0; s < questions.length; s++) {
                    if (questions[s].userResponseId) {
                        formData.push({
                            stateTypeId: questions[s].stateTypeId,
                            questionTypeId: questions[s].questionTypeId,
                            questionId: questions[s].questionId,
                            responseId: questions[s].userResponseId
                        })
                    }
                }
            }
        }

        return { status: true, data: formData }
    }

    return { status: false, data: [] }
}

export const tokenDecode = (token) => {
    if (!token) return null;
    return decodeToken(token);
}

export const getParamsFromURL = (search) => {
    return new URLSearchParams(search);
}

export const getRequiredFieldIsValid = (form) => {
    if (!form) {
        return { isError: true, fields: [] };
    }
    let requiredFields = [];
    const inputs = form.querySelectorAll('input');
    const select = form.querySelectorAll('select');

    if (inputs && inputs.length) {
        for (let n = 0; n < inputs.length; n++) {
            const input = inputs[n];
            if (input.hasAttribute('required')) {

                requiredFields.push({ [input.name]: input.value, isValid: input.value && input.value !== "" ? true : false })
            }
        }
    }
    if (select && select.length) {
        for (let s = 0; s < select.length; s++) {
            const selectField = select[s];
            if (selectField.hasAttribute('required')) {

                requiredFields.push({ [selectField.name]: selectField.value, isValid: selectField.value && selectField.value !== "" ? true : false })
            }
        }
    }

    const isNotValid = requiredFields.filter((item) => !item.isValid)

    return { isError: false, fields: isNotValid };

}