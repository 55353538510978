import * as ACTION_TYPES from '../type';

const initialState = {
    type: '',
    getLoader: false,
    introData: [],
    videoData: [],
    submitLoading: false,
    introProcess: false,
    currentImgIndex: null,
    percentage: 0,
    currentStateAnswers: [],
    futureStateAnswers: [],
    toolsToGetStateAnswers: [],
    toolsSelectQuestions: [],
    completeSurveyModel: false,
}

const ProcessReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.QUESTION_GET_LOADING:
            return {
                ...state,
                getLoader: action.payload
            }
        case ACTION_TYPES.TOGGLE_PROCESS:
            localStorage.setItem('processType', action.payload);
            return {
                ...state,
                type: action.payload
            }
        case ACTION_TYPES.TOGGLE_COMPLETE_SURVEY_MODEL:
            return {
                ...state,
                completeSurveyModel: action.payload
            }
        case ACTION_TYPES.VIDEO_DATA:
            localStorage.setItem('videoData', JSON.stringify(action.payload));
            return {
                ...state,
                videoData: action.payload
            }
        case ACTION_TYPES.TOGGLE_CURRENT_STATE_ANSWER_ARRAY:
            localStorage.setItem('currentStateAnswers', JSON.stringify(action.payload))
            return {
                ...state,
                currentStateAnswers: action.payload
            }
        case ACTION_TYPES.TOGGLE_FUTURE_STATE_ANSWER_ARRAY:
            localStorage.setItem('futureStateAnswers', JSON.stringify(action.payload))
            return {
                ...state,
                futureStateAnswers: action.payload
            }
        case ACTION_TYPES.TOGGLE_TOOLS_TO_GET_ANSWER_ARRAY:
            localStorage.setItem('toolsToGetStateAnswers', JSON.stringify(action.payload))
            return {
                ...state,
                toolsToGetStateAnswers: action.payload
            }
        case ACTION_TYPES.PERCENTAGE_TOGGLE_HEADER:
            return {
                ...state,
                percentage: action.payload
            }
        case ACTION_TYPES.PROCESS_DATA:
            // localStorage.setItem('introData', JSON.stringify(action.payload));
            return {
                ...state,
                introData: action.payload
            }
        case ACTION_TYPES.TOGGLE_TOOLS_TO_GET_SELECT_QUESTION:
            localStorage.setItem('toolsQuestionTypesIds', JSON.stringify(action.payload));
            return {
                ...state,
                toolsSelectQuestions: action.payload
            }
        case ACTION_TYPES.QUESTION_HEADER_IMAGE_INDEX:
            return {
                ...state,
                currentImgIndex: action.payload
            }
        case ACTION_TYPES.PROCESS_SUBMIT_REQUEST:
            return {
                ...state,
                submitLoading: true
            }
        case ACTION_TYPES.PROCESS_SUBMIT_FAILED:
            return {
                ...state,
                submitLoading: false
            }
        case ACTION_TYPES.PROCESS_SUBMIT_SUCCESS:
            // localStorage.removeItem('introData')
            // localStorage.removeItem('processType')
            // localStorage.removeItem('token')
            // localStorage.removeItem('companyName')
            // localStorage.setItem('introProcess', true)
            return {
                ...state,
                submitLoading: false,
                // type: '',
                // introData: [],
                // introProcess: true
            }
        case ACTION_TYPES.REMOVE_ALL_FROM_LOCALSTORAGE:
            localStorage.removeItem('introData')
            localStorage.removeItem('processType')
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            // localStorage.removeItem('companyName')
            localStorage.removeItem('introProcess')
            localStorage.removeItem('currentStateAnswers')
            localStorage.removeItem('futureStateAnswers')
            localStorage.removeItem('toolsToGetStateAnswers')
            localStorage.removeItem('toolsQuestionTypesIds')
            localStorage.removeItem('selectedQuestions')
            localStorage.removeItem('flow')
            localStorage.removeItem('videoData')
            localStorage.removeItem('adminFlow')
            return {
                ...state,
                submitLoading: false,
                type: '',
                introData: [],
                videoData: [],
                introProcess: false,
                currentImgIndex: null,
                percentage: 0
            }
        default:
            return state;
    }
}

export default ProcessReducer;