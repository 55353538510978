import * as ACTION_TYPES from '../type';

export const clearFromLocalStorage = () => ({
    type: ACTION_TYPES.REMOVE_ALL_FROM_LOCALSTORAGE
})

export const toggleProccess = (type) => ({
    type: ACTION_TYPES.TOGGLE_PROCESS,
    payload: type
})

export const toggleGetLoader = (bool) => ({
    type: ACTION_TYPES.QUESTION_GET_LOADING,
    payload: bool
})

export const toggleVideoData = (data) => ({
    type: ACTION_TYPES.VIDEO_DATA,
    payload: data
})

export const toggleCurrentStateAnswerArr = (data) => ({
    type: ACTION_TYPES.TOGGLE_CURRENT_STATE_ANSWER_ARRAY,
    payload: data
})

export const toggleFutureStateAnswerArr = (data) => ({
    type: ACTION_TYPES.TOGGLE_FUTURE_STATE_ANSWER_ARRAY,
    payload: data
})

export const toggleToolsToGetAnswerArr = (data) => ({
    type: ACTION_TYPES.TOGGLE_TOOLS_TO_GET_ANSWER_ARRAY,
    payload: data
})

export const toggleIntroData = (data) => ({
    type: ACTION_TYPES.PROCESS_DATA,
    payload: data
});

export const togglePercentageHeader = (data) => ({
    type: ACTION_TYPES.PERCENTAGE_TOGGLE_HEADER,
    payload: data
});

export const toggleQuestionHeaderImg = (index) => ({
    type: ACTION_TYPES.QUESTION_HEADER_IMAGE_INDEX,
    payload: index
});

export const toggleToolsSelectQuestion = (data) => ({
    type: ACTION_TYPES.TOGGLE_TOOLS_TO_GET_SELECT_QUESTION,
    payload: data
});

export const processSubmitRequest = () => ({
    type: ACTION_TYPES.PROCESS_SUBMIT_REQUEST
});
export const processSubmitSuccess = () => ({
    type: ACTION_TYPES.PROCESS_SUBMIT_SUCCESS
});
export const processSubmitFailed = () => ({
    type: ACTION_TYPES.PROCESS_SUBMIT_FAILED
});

export const toggleCompleteSurvey = (bool) => ({
    type: ACTION_TYPES.TOGGLE_COMPLETE_SURVEY_MODEL,
    payload: bool
});