import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function MainLoader() {
    return (
        <div className="loader-container">
            <CircularProgress sx={{ color: '#FF6500', width: '60px !important', height: '60px !important' }} />
            {/* <div className="loader"></div> */}
        </div>
    )
}
