export const TOGGLE_PROCESS = "TOGGLE_PROCESS";
export const PROCESS_DATA = "PROCESS_DATA";
export const VIDEO_DATA = "VIDEO_DATA";
export const QUESTION_GET_LOADING = "QUESTION_GET_LOADING";
export const PROCESS_SUBMIT_REQUEST = "PROCESS_SUBMIT_REQUEST"
export const PROCESS_SUBMIT_SUCCESS = "PROCESS_SUBMIT_SUCCESS"
export const PROCESS_SUBMIT_FAILED = "PROCESS_SUBMIT_FAILED"
export const QUESTION_HEADER_IMAGE_INDEX = "QUESTION_HEADER_IMAGE_INDEX"
export const PERCENTAGE_TOGGLE_HEADER = "PERCENTAGE_TOGGLE_HEADER";
export const REMOVE_ALL_FROM_LOCALSTORAGE = "REMOVE_ALL_FROM_LOCALSTORAGE";
export const TOGGLE_CURRENT_STATE_ANSWER_ARRAY = "TOGGLE_CURRENT_STATE_ANSWER_ARRAY";
export const TOGGLE_FUTURE_STATE_ANSWER_ARRAY = "TOGGLE_FUTURE_STATE_ANSWER_ARRAY";
export const TOGGLE_TOOLS_TO_GET_ANSWER_ARRAY = "TOGGLE_TOOLS_TO_GET_ANSWER_ARRAY";
export const TOGGLE_TOOLS_TO_GET_SELECT_QUESTION = "TOGGLE_TOOLS_TO_GET_SELECT_QUESTION";
export const TOGGLE_COMPLETE_SURVEY_MODEL = "TOGGLE_COMPLETE_SURVEY_MODEL";