import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute';
import Layout from '../components/Layout';
import MainLoader from '../components/MainLoader';
// pages
const WelcomePage = lazy(() => import('../components/welcomePage'));
const SignUp = lazy(() => import('../components/signUp'));
const AdminLogin = lazy(() => import('../components/AdminLogin'));
const IntroductionPage = lazy(() => import('../components/introductionPage'));
const Dashboard = lazy(() => import('../components/Dashboard'));

const routes = [
    { path: '/', component: WelcomePage, exact: true, isPrivate: false },
    { path: '/signup', component: SignUp, exact: true, isPrivate: false },
    { path: '/admin', component: AdminLogin, exact: true, isPrivate: false },
    { path: '/survey', component: IntroductionPage, exact: true, isPrivate: true },
    { path: '/dashboard', component: Dashboard, exact: true, isPrivate: true }
]

export default function Routes() {
    return (
        <Switch>
            {routes.map((item, i) => {
                if (item.isPrivate) {
                    return <PrivateRoute key={i} path={item.path} exact={item.exact} component={props => <Suspense fallback={<MainLoader />}><Layout {...props}><item.component /></Layout></Suspense>} />
                }

                return <Route key={i} path={item.path} exact={item.exact} component={props => <Suspense fallback={<MainLoader />}><item.component /></Suspense>} />
            })}
        </Switch>
    )
}