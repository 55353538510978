import { createStore, applyMiddleware, compose } from 'redux';
import rootReducers from './reducers';
import thunk from 'redux-thunk';

const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// without redux devtools
// export default createStore(RootReducers, compose(applyMiddleware(thunk)))

// with redux devtools
export const store = createStore(rootReducers, composeEnhancer(applyMiddleware(thunk)));